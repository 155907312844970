import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import 'styles/showroomafspraak.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import GravityForm from 'components/GravityForm'
import { BannerDefault } from 'components/Elements'
import Content from 'components/Content'

// Third Party
import _ from 'lodash'

// Images
import romazo from 'img/romazo.jpg'

function ShowroomAfspraak() {
  const {
    appointment: {
      acf,
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`
  {
    appointment: wordpressPage(wordpress_id: {eq: 777}) {
      acf {
       description
       appointment {
           description
           iframe
       }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="contact">
        <iframe
          title="google-maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.7964744668525!2d5.361201116160054!3d52.22891537976011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c640b020e5effd%3A0x5b3970902352207!2sDe%20Kronkels%2020%2C%203752%20LM%20Bunschoten-Spakenburg!5e0!3m2!1snl!2snl!4v1578565700457!5m2!1snl!2snl"
          width="100%"
          height="350"
          frameBorder="0"
          style={{ border: 0}}
          allowFullScreen=""
        />
        <BannerDefault className="color-background-main py-4">
          <h2 className="w-100 text-center font-size-xl font-weight-xl">Maak een showroomafspraak</h2>
        </BannerDefault>
        <div className="container py-5">
          <div className="row pb-5">
            <div className="col-lg-6 pr-lg-5 omschrijving">
                <Content className="d-flex flex-wrap mb-4" content={acf.description} />
                <Content content={acf.appointment.description} className="d-flex flex-wrap" />
            </div>
            <div className="col-lg-6 iframe">
                <Content content={acf.appointment.iframe} />
            </div>
            
            
          </div>

        </div>
      </div>
      {/* <Modal /> */}
    </Layout>
  )
}

export default ShowroomAfspraak
